import { atom, useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { useCallback, useEffect } from 'react'

import { STARLOCAL } from './hooks'

export enum RtarSortMethod {
  RTAR = 'rtar',
  ALL = 'ALL',
  ROX = 'ROX',
  ETH = 'ETH',
  BTC = 'BTC',
  USDB = 'USDB',
}

export enum TokenSortMethod {
  PAIRS = 'Pairs',
  PRICE = 'Price',
  CHANGES = 'Changes',
}

export const TokenSortMethodAtom = atom<TokenSortMethod>(TokenSortMethod.PRICE)
export const TokenSortAscendingAtom = atom<boolean>(false)

export function useSetTokenSortMethod(newSortMethod: TokenSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(TokenSortMethodAtom)
  const [sortAscending, setSortAscending] = useAtom(TokenSortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending(!sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, sortAscending, newSortMethod])
}

export const rtarString = atomWithReset<string>('')
export const RtarsortMethodAtom = atom<RtarSortMethod>(RtarSortMethod.ALL)

export const searchRtarList = atom<{ [key: string]: string }>({})

export const useRtarAcitons = () => {
  const [list, setList] = useAtom(searchRtarList)
  const [, setSortMethod] = useAtom(RtarsortMethodAtom)

  const updataList = useCallback(
    (val: string) => {
      const data = JSON.parse(JSON.stringify(list))
      data[val.toLocaleLowerCase()] = val
      setList(data)
      window.localStorage.setItem(STARLOCAL, JSON.stringify(data))
    },
    [list, setList]
  )

  const updataAll = useCallback(
    (val: { [key: string]: string }) => {
      setList(val)
      window.localStorage.setItem(STARLOCAL, JSON.stringify(val))
    },
    [setList]
  )
  const rmDataList = useCallback(
    (val: string) => {
      const data = JSON.parse(JSON.stringify(list))
      if (data[val.toLocaleLowerCase()]) {
        delete data[val.toLocaleLowerCase()]
        updataAll(data)
      }
    },
    [list, updataAll]
  )

  const setRtarsort = useCallback(
    (val: RtarSortMethod) => {
      setSortMethod(val)
    },
    [setSortMethod]
  )

  return {
    updataList,
    updataAll,
    rmDataList,
    setRtarsort,
  }
}

export const useInitrtarList = () => {
  const { updataAll } = useRtarAcitons()

  useEffect(() => {
    const list = window.localStorage.getItem(STARLOCAL)
    if (list) {
      updataAll(JSON.parse(list) as { [key: string]: string })
    }
  }, [updataAll])
}
