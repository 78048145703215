import { Box } from '@mui/material'
import { useIsMobile } from 'nft/hooks'
import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const HoverBarBox = styled(Box)``

export const HoverBar = ({ textProps, navDown }: { textProps: ReactNode; navDown: ReactNode }) => {
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef] = useState<HTMLDivElement | null>(null)

  const isMoble = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const showDropdownMenu = () => {
      !isMoble && setIsOpen(true)
    }

    const closeDownMenu = () => {
      if (isMoble) {
        setIsOpen(!isOpen)
      } else {
        setIsOpen(false)
      }
    }

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node

      return target && !isMoble && !tooltipRef?.contains(target) && setIsOpen(false)
    }

    targetRef?.addEventListener('mouseenter', showDropdownMenu)
    targetRef?.addEventListener('mouseleave', hideDropdownMenu)
    targetRef?.addEventListener('click', closeDownMenu)
    window?.addEventListener('click', windowClick)

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu)
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
      targetRef?.removeEventListener('click', closeDownMenu)
      window?.removeEventListener('click', windowClick)
    }
  }, [isMoble, isOpen, setIsOpen, targetRef, tooltipRef])

  // useOnClickOutside(targetRef, isOpen ? toggleOpen : undefined)

  const windowClick = (e: any) => {
    const node = e.target
    const isContain = targetRef?.contains(node)
    if (isOpen && !isContain) {
      setIsOpen(false)
    }
  }

  return (
    <HoverBarBox ref={setTargetRef}>
      {textProps}
      {isOpen && navDown}
    </HoverBarBox>
  )
}
