import { Trans } from '@lingui/macro'
import { ButtonRetry } from 'components/Button'
import { ColumnCenter } from 'components/Column'
import { StyledCloseIcon2 } from 'components/Icons/StyledIcon'
import QuestionHelper from 'components/QuestionHelper'
import Row from 'components/Row'
import { StyledConfirmClose } from 'components/TransactionConfirmationModal'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { PendingModalContainer } from '.'
import { AnimatedEntranceFailedIcon, LogoContainer } from './Logos'

export enum PendingModalError {
  TOKEN_APPROVAL_ERROR,
  PERMIT_ERROR,
  CONFIRMATION_ERROR,
  WRAP_ERROR,
}

interface ErrorModalContentProps {
  errorType: PendingModalError
  onRetry: () => void
  onDismiss: () => void
}

function getErrorContent(errorType: PendingModalError) {
  switch (errorType) {
    case PendingModalError.TOKEN_APPROVAL_ERROR:
      return {
        title: <Trans>Token approval failed</Trans>,
        label: <Trans>Why are approvals required?</Trans>,
        tooltipText: (
          <Trans>
            This provides the Uniswap protocol access to your token for trading. For security, this will expire after 30
            days.
          </Trans>
        ),
      }
    case PendingModalError.PERMIT_ERROR:
      return {
        title: <Trans>Permit approval failed</Trans>,
        label: <Trans>Why are permits required?</Trans>,
        tooltipText: (
          <Trans>Permit2 allows token approvals to be shared and managed across different applications.</Trans>
        ),
      }
    case PendingModalError.CONFIRMATION_ERROR:
      return {
        title: <Trans>Swap failed</Trans>,
      }
    case PendingModalError.WRAP_ERROR:
      return {
        title: <Trans>Wrap failed</Trans>,
      }
  }
}

export function ErrorModalContent({ errorType, onRetry, onDismiss }: ErrorModalContentProps) {
  const theme = useTheme()

  const { title, label, tooltipText } = getErrorContent(errorType)

  return (
    <div className="">
      <LogoContainer>
        <div className="bg fail-bg">
          {/* <AlertTriangle
            data-testid="pending-modal-failure-icon"
            strokeWidth={1}
            color={theme.accentFailure}
            size="48px"
          /> */}
          <AnimatedEntranceFailedIcon />
        </div>
      </LogoContainer>
      <StyledConfirmClose className="">
        <StyledCloseIcon2 onClick={onDismiss} data-testid="error-close-icon-2" />
      </StyledConfirmClose>
      <PendingModalContainer gap="16px">
        {/* <RowBetween>
          <div />
          <StyledCloseIcon onClick={onDismiss} style={{ marginRight: '8px' }} />
        </RowBetween> */}
        <ColumnCenter gap="8px" mt="16px">
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {title}
          </ThemedText.TextPrimary>
          {label && (
            <Row justify="center">
              <ThemedText.TextSecondary fontSize={16} fontWeight={400}>
                {label}
              </ThemedText.TextSecondary>
            </Row>
          )}
          {tooltipText && <QuestionHelper text={tooltipText} />}
        </ColumnCenter>
        <Row justify="center">
          <ButtonRetry width="200px" marginBottom="16px" onClick={onRetry}>
            <Trans>Retry</Trans>
          </ButtonRetry>
        </Row>
      </PendingModalContainer>
    </div>
  )
}
