import { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'

export enum BadgeVariant {
  DEFAULT = 'DEFAULT',
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  PROMOTIONAL = 'PROMOTIONAL',
  BRANDED = 'BRANDED',
  SOFT = 'SOFT',

  WARNING_OUTLINE = 'WARNING_OUTLINE',
}

interface BadgeProps {
  variant?: BadgeVariant
}

const Badge = styled.div<PropsWithChildren<BadgeProps>>`
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  padding: 8px 10px;
  background: ${({ theme }) => theme.badgeBackground};
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
`

export default Badge
