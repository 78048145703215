import { Currency } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import { StyledDelIcon } from 'components/Icons/StyledIcon'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { AutoRow } from 'components/Row'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { Text } from 'rebass'
import { useDelUserToken, useUserAddedTokens } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { currencyId } from 'utils/currencyId'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    // display: none;
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  /*
   border: 1px solid ${({ theme, disable }) => (disable ? theme.buttonPrimaryBackground : theme.backgroundOutline)}; */
  position: relative;
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 16px;
  display: flex;
  padding: 2px 16px 2px 2px;
  padding-right: 18px;
  /* color: ${({ theme, disable }) => disable && theme.buttonPrimaryBackground}; */
  /* background-color: ${({ theme, disable }) => disable && theme.accentActiveSoft}; */
  align-items: center;
  cursor: pointer;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme }) => theme.hoverDefault};
  }
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: number
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
  searchQuery: string
  isAddressSearch: string | false
}) {
  // const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []
  const bases = useUserAddedTokens()
  const delToken = useDelUserToken()
  return bases.length > 0 ? (
    <MobileWrapper gap="md">
      <AutoRow gap="4px">
        {bases.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)

          return (
            <BaseWrapper
              tabIndex={0}
              onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
              onClick={() => !isSelected && onSelect(currency)}
              disable={isSelected}
              key={currencyId(currency)}
            >
              <CurrencyLogoFromList currency={currency} />
              <Text fontWeight={500} fontSize={16}>
                {currency.symbol}
              </Text>
              <StyledDelIcon
                style={{ position: 'absolute', right: '-6px', top: '-4px' }}
                onClick={(e) => {
                  e.preventDefault(), e.stopPropagation(), delToken(currency.wrapped)
                }}
              />
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </MobileWrapper>
  ) : null
}

/** helper component to retrieve a base currency from the active token lists */
function CurrencyLogoFromList({ currency }: { currency: Currency }) {
  const token = useTokenInfoFromActiveList(currency)

  return <CurrencyLogo currency={token} style={{ marginRight: 4 }} size="30px" />
}
