import { Box } from '@mui/material'
import Row from 'components/Row'
import { MaketTable } from 'constants/misc'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

const TabWrapper = styled(Row)`
  grid-gap: 8px;
  height: 42px;
  .tabItem {
    flex: 1;
    cursor: pointer;
  }
`

const TradeTabWrapper = styled(Row)`
  width: auto;
  flex: 1;
  justify-content: flex-start;
  grid-gap: 8px;
  height: 42px;
  .tabItem {
    padding: 10px 20px;
    cursor: pointer;
  }
  @media screen and (max-width: ${BREAKPOINTS.xs}px) {
    .tabItem {
      padding: 10px 12px;
    }
  }
`

const StyledActiveDot = styled.div<{ bg?: string }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background: ${({ bg }) => (bg ? bg : 'linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%)')};
  border-radius: 50%;
`

interface TabProps {
  curr: number
  handlerChange: (value: number) => void
  bg?: string
}

export default function Tab({ curr, handlerChange, bg }: TabProps) {
  const tabList = MaketTable
  return (
    <TabWrapper>
      {tabList?.map((tab: any, i: number) => (
        <Row
          justify="center"
          className="tabItem"
          key={i}
          onClick={() => {
            handlerChange(i)
          }}
        >
          {curr == i ? (
            <Box display="flex" alignItems="center">
              <StyledActiveDot bg={bg} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                {tab}
              </ThemedText.TextPrimary>
            </Box>
          ) : (
            <ThemedText.TextSecondary fontSize={16}>{tab}</ThemedText.TextSecondary>
          )}
        </Row>
      ))}
    </TabWrapper>
  )
}

export function TradeTab({ curr, handlerChange, bg }: TabProps) {
  const tabList = MaketTable

  return (
    <TradeTabWrapper>
      {tabList?.map((tab: any, i: number) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="tabItem"
          key={i}
          onClick={() => {
            handlerChange(i)
          }}
        >
          {curr == i ? (
            <Box display="flex" alignItems="center">
              <StyledActiveDot bg={bg} />
              <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                {tab}
              </ThemedText.TextPrimary>
            </Box>
          ) : (
            <ThemedText.TextSecondary fontSize={16}>{tab}</ThemedText.TextSecondary>
          )}
        </Box>
      ))}
    </TradeTabWrapper>
  )
}
