import { AllPoolsProps, useAllPools } from 'hooks/useQueryAllPools'
import { useAtomValue } from 'jotai/utils'
import { useMemo } from 'react'

import {
  RtarSortMethod,
  RtarsortMethodAtom,
  rtarString,
  searchRtarList,
  TokenSortAscendingAtom,
  TokenSortMethod,
  TokenSortMethodAtom,
} from './state'

export const STARLOCAL = 'keyStar'

export const useSortPools = () => {
  const defaultPools = useAllPools()
  const tokenSortMethod = useAtomValue(TokenSortMethodAtom)
  const sortAscending = useAtomValue(TokenSortAscendingAtom)

  const SortMethod = useAtomValue(RtarsortMethodAtom)

  const dataRart = useAtomValue(searchRtarList)

  const filteredPools = useMemo(() => {
    if (!defaultPools) return
    return Object.values(defaultPools)
  }, [defaultPools])
  const sortData = useMemo(() => {
    if (!filteredPools) return
    let poolArray = filteredPools
   
    if (SortMethod == RtarSortMethod.RTAR) {
      poolArray = poolArray.filter((x) => dataRart[x.id.toLocaleLowerCase()])
    } else {
      if (SortMethod != RtarSortMethod.ALL) {
        poolArray = poolArray.filter((x) => {
          const { symbol, name } = x.token0
          const { symbol: symbol1, name: name1 } = x.token1
          if (!symbol || !name || !symbol1 || !name1) return
          const allStrsymbol = symbol + symbol1
          const allStrNAME = name + name1
          const nameIncludesFilterString = allStrNAME.toLowerCase().includes(SortMethod.toLowerCase())
          const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(SortMethod.toLowerCase())
          return nameIncludesFilterString || symbolIncludesFilterString
        })
        
      }
    }
  

    switch (tokenSortMethod) {
      case TokenSortMethod.PRICE:
        poolArray = poolArray.sort((a, b) => Number(b.sqrtPrice || 0) - Number(a.sqrtPrice || 0))
        break

      case TokenSortMethod.CHANGES:
        poolArray = poolArray.sort((a, b) => Number(b.change || 0) - Number(a.change || 0))
        break
    }
    //TODO： del
    poolArray = poolArray.sort((a, b) => Number(b.totalValueLockedUSD ) - Number(a.totalValueLockedUSD ))
    return sortAscending ? poolArray.reverse() : poolArray
  }, [SortMethod, dataRart, filteredPools, sortAscending, tokenSortMethod])
  const data2 = useFilteredPool(sortData)
  return data2
}

const useFilteredPool = (voteData: AllPoolsProps[] | undefined) => {
  const filterString = useAtomValue(rtarString)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!voteData) return undefined
    let returnDatas = voteData
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token) => {
        const { symbol, name } = token.token0 as any
        const { symbol: symbol1, name: name1 } = token.token1 as any
        const allStrsymbol = symbol + symbol1
        const allStrNAME = name + name1
        const nameIncludesFilterString = allStrNAME.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = allStrsymbol.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString
      })
    }
    return returnDatas
  }, [lowercaseFilterString, voteData])
}
