import { AutoColumn } from 'components/Column'
import Leverage from 'components/Leverage'
import Row, { RowBetween } from 'components/Row'
import { Input } from 'components/Settings/Input'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import { usePositionSwapActionHandlers, useTradeSWAPState } from 'state/positionSwap/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 36px;
  padding: 0 6px;
  margin-left: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
`

export function SliderBox() {
  const { sliederLever } = useTradeSWAPState()

  const { onSliederLever } = usePositionSwapActionHandlers()
  const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(sliederLever, onSliederLever)
  const min = 1.1
  const max = 50
  const InputChange = (e: any) => {
    if (e.target.value <= 0) {
      onPercentSelectForSlider(e.target.value)
    } else if (e.target.value >= 50) {
      onPercentSelectForSlider(50)
    } else {
      onPercentSelectForSlider(Number(e.target.value || 1.1))
    }
  }
  const InputBlur = (e: any) => {
    if (e.target.value <= 1) {
      onPercentSelectForSlider(1.1)
    } else if (e.target.value >= 50) {
      onPercentSelectForSlider(50)
    } else {
      onPercentSelectForSlider(Number(e.target.value || 1.1))
    }
  }
  return (
    <Row>
      <AutoColumn width="100%" style={{ flex: 1 }}>
        <Leverage percent={percentForSlider} onSliderChange={onPercentSelectForSlider} />
        <RowBetween mt="6px">
          <ThemedText.TextPrimary>{min}x</ThemedText.TextPrimary>
          <ThemedText.TextPrimary>{max}x</ThemedText.TextPrimary>
        </RowBetween>
      </AutoColumn>
      <StyledInputRow>
        <Input
          type="number"
          className="Leverage-input"
          defaultValue={percentForSlider}
          value={percentForSlider}
          onChange={InputChange}
          onBlur={InputBlur}
          min={1.1}
          max={50}
          align="left"
        />
        <div className="x">x</div>
      </StyledInputRow>
    </Row>
  )
}
