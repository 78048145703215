import Row from 'components/Row'
import { ThemedText } from 'theme'

import { ReactComponent as CheckboxUnchecked } from '../../assets/imgs/check.svg'
import { ReactComponent as CheckboxChecked } from '../../assets/imgs/checked.svg'

interface CheckboxProps {
  isChecked: boolean
  setIsChecked: any
  content: any
}

export default function Checkbox({ isChecked, setIsChecked, content }: CheckboxProps) {
  return (
    <Row width="auto" className="Checkbox" onClick={() => setIsChecked(!isChecked)} style={{ cursor: 'pointer' }}>
      {isChecked ? (
        <CheckboxChecked className="Checkbox-icon active" />
      ) : (
        <CheckboxUnchecked className="Checkbox-icon inactive" />
      )}
      {isChecked ? (
        <ThemedText.TextPrimary ml="16px">{content}</ThemedText.TextPrimary>
      ) : (
        <ThemedText.TextSecondary fontSize={14} ml="16px">
          {content}
        </ThemedText.TextSecondary>
      )}
    </Row>
  )
}
