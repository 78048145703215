import { t, Trans } from '@lingui/macro'
import { Box } from '@mui/system'
import { ChainId, Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as ExplorerIcon } from 'assets/imgs/confirmation/explorer.svg'
import { StyledCloseIcon, StyledCloseIcon2 } from 'components/Icons/StyledIcon'
import { StyledChainInfo } from 'components/swap/PendingModalContent'
import {
  AnimatedEntranceFailedIcon,
  AnimatedEntranceSuccessedIcon,
  AnimatedPendingIcon,
  LogoContainer,
} from 'components/swap/PendingModalContent/Logos'
import { useActiveChainId } from 'connection/useActiveChainId'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedL2ChainId } from 'constants/chains'
import useCurrencyLogoURIs from 'lib/hooks/useCurrencyLogoURIs'
import { ReactNode, useCallback, useState } from 'react'
import { ArrowUpCircle, CheckCircle } from 'react-feather'
import { useIsTransactionConfirmed, useTransaction } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { isL2ChainId } from 'utils/chains'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { BREAKPOINTS, ExternalLink, ThemedText } from '../../theme'
import { TransactionSummary } from '../AccountDetails/TransactionSummary'
import { ButtonLight, ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Modal from '../Modal'
import Row, { RowBetween, RowFixed } from '../Row'

const Wrapper = styled.div<{ paddingStr?: string }>`
  width: 100%;
  padding: ${({ paddingStr }) => (paddingStr ? paddingStr : '16px')};
  border-radius: 24px;
  position: relative;
  /* @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 90vw;
  } */
`

export const StyledConfirmClose = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 999;
`

const BottomSection = styled(AutoColumn)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? '20px 0' : '32px 0;')};
`

const ConfirmationModalContentWrapper = styled(AutoColumn)`
  padding-bottom: 16px;
`

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  pendingText: ReactNode
  inline?: boolean // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        {!inline && (
          <RowBetween className="confirmationPendingContent-header">
            <div />
            <StyledCloseIcon onClick={onDismiss} style={{ marginRight: '8px' }} />
          </RowBetween>
        )}
        {/* <ConfirmedIcon inline={inline}>
          <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
        </ConfirmedIcon> */}
        <LogoContainer>
          <div className="bg pending-bg">
            <AnimatedPendingIcon />
          </div>
        </LogoContainer>
        <Box mt="186px">
          <AutoColumn gap="md" justify="center">
            <ThemedText.TextPrimary fontWeight={700} fontSize={16} textAlign="center">
              <Trans>Waiting for confirmation</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary textAlign="center">{pendingText}</ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={12} fontWeight={400} textAlign="center" marginBottom="12px">
              <Trans>Confirm this transaction in your wallet</Trans>
            </ThemedText.TextSecondary>
          </AutoColumn>
        </Box>
      </AutoColumn>
    </Wrapper>
  )
}
function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
}: {
  onDismiss: () => void
  hash?: string
  chainId: number
  currencyToAdd?: Currency
  inline?: boolean // not in modal
}) {
  const theme = useTheme()

  const { connector } = useWeb3React()

  const token = currencyToAdd?.wrapped
  const logoURL = useCurrencyLogoURIs(token)[0]

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: logoURL,
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false))
  }, [connector, logoURL, token])

  const explorerText = chainId === ChainId.MAINNET ? t`View on Etherscan` : t`View on Block Explorer`

  return (
    <Wrapper>
      <AutoColumn>
        {!inline && (
          <RowBetween className="transactionSubmittedContent-header">
            <div />
            <StyledCloseIcon onClick={onDismiss} style={{ marginRight: '8px' }} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <ArrowUpCircle strokeWidth={1} size={inline ? '40px' : '75px'} color={theme.accentActive} />
        </ConfirmedIcon>
        <ConfirmationModalContentWrapper gap="md" justify="center">
          <ThemedText.TextPrimary fontSize={16} fontWeight={700} textAlign="center">
            <Trans>Transaction submitted</Trans>
          </ThemedText.TextPrimary>
          {currencyToAdd && connector.watchAsset && (
            <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
              {!success ? (
                <RowFixed>
                  <Trans>Add {currencyToAdd.symbol}</Trans>
                </RowFixed>
              ) : (
                <RowFixed>
                  <Trans>Added {currencyToAdd.symbol} </Trans>
                  <CheckCircle size="16px" stroke={theme.accentSuccess} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }} data-testid="dismiss-tx-confirmation">
            <ThemedText.HeadlineSmall color={theme.accentTextLightPrimary}>
              {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
            </ThemedText.HeadlineSmall>
          </ButtonPrimary>
          {chainId && hash && (
            <ExternalLink href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}>
              <ThemedText.Link color={theme.accentAction}>{explorerText}</ThemedText.Link>
            </ExternalLink>
          )}
        </ConfirmationModalContentWrapper>
      </AutoColumn>
    </Wrapper>
  )
}

function TransactionErrorContent({
  inline,
  txError,
  onDismiss,
}: {
  inline?: boolean
  txError?: string
  onDismiss: () => void
}) {
  const theme = useTheme()
  return (
    <Wrapper>
      {/* {!inline && (
        <RowBetween className="transactionErrorContent-header">
          <div />
          <StyledCloseIcon onClick={onDismiss} style={{ marginRight: '8px' }} />
        </RowBetween>
      )}
      <ConfirmedIcon inline={inline}>
        <AlertCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.accentFailure} />
      </ConfirmedIcon> */}
      <LogoContainer>
        <div className="bg fail-bg">
          <AnimatedEntranceFailedIcon />
        </div>
      </LogoContainer>
      <StyledConfirmClose className="">
        <StyledCloseIcon2 onClick={onDismiss} data-testid="transactionErrorContent-close-icon" />
      </StyledConfirmClose>
      <Box mt="186px" p="0 8px">
        <ConfirmationModalContentWrapper gap="8px" justify="center">
          <ThemedText.TextPrimary fontSize={16} fontWeight={700} color="short">
            <Trans>{txError}</Trans>
          </ThemedText.TextPrimary>
        </ConfirmationModalContentWrapper>
        <ButtonPrimary onClick={onDismiss} data-testid="dismiss-tx-confirmation">
          <ThemedText.HeadlineSmall color={theme.accentTextLightPrimary}>
            {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
          </ThemedText.HeadlineSmall>
        </ButtonPrimary>
      </Box>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  gap,
  bottomContent,
  onDismiss,
  topContent,
  headerContent,
}: {
  title: ReactNode
  gap?: string
  onDismiss: () => void
  topContent: () => ReactNode
  bottomContent?: () => ReactNode
  headerContent?: () => ReactNode
}) {
  return (
    <Wrapper>
      <AutoColumn gap={gap ? gap : 'sm'} className="confirmation-header">
        {headerContent?.()}
        {title ? (
          <Row>
            <Row justify="center" marginLeft="24px">
              <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
            </Row>
            <StyledCloseIcon
              onClick={onDismiss}
              data-testid="confirmation-close-icon"
              style={{ marginRight: '8px', cursor: 'pointer' }}
            />
          </Row>
        ) : (
          <StyledConfirmClose className="">
            <StyledCloseIcon2 onClick={onDismiss} data-testid="confirmation-close-icon-2" />
          </StyledConfirmClose>
        )}
        {topContent()}
      </AutoColumn>
      {bottomContent && <BottomSection gap="12px">{bottomContent()}</BottomSection>}
    </Wrapper>
  )
}

export function ConfirmationModalContent2222({
  title,
  gap,
  paddingStr,
  bottomContent,
  onDismiss,
  topContent,
  headerContent,
}: {
  title: ReactNode
  gap?: string
  paddingStr?: string
  onDismiss: () => void
  topContent: ReactNode
  bottomContent?: ReactNode
  headerContent?: ReactNode
}) {
  return (
    <Wrapper paddingStr={paddingStr}>
      <AutoColumn gap={gap ? gap : 'sm'} className="confirmation-header">
        {headerContent}
        {title ? (
          <Row className="confirmationModalContent-title">
            <Row justify="center" marginLeft="24px">
              <ThemedText.SubHeader>{title}</ThemedText.SubHeader>
            </Row>
            <StyledCloseIcon onClick={onDismiss} data-testid="confirmation-close-icon" style={{ marginRight: '8px' }} />
          </Row>
        ) : (
          <StyledConfirmClose className="">
            <StyledCloseIcon2 onClick={onDismiss} data-testid="confirmation-close-icon-2" />
          </StyledConfirmClose>
        )}
        {topContent}
      </AutoColumn>
      {bottomContent && <BottomSection gap="12px">{bottomContent}</BottomSection>}
    </Wrapper>
  )
}

function L2Content({
  onDismiss,
  chainId,
  hash,
  pendingText,
  inline,
}: {
  onDismiss: () => void
  hash?: string
  chainId: SupportedL2ChainId
  currencyToAdd?: Currency
  pendingText: ReactNode
  inline?: boolean // not in modal
}) {
  const theme = useTheme()

  const transaction = useTransaction(hash)
  const confirmed = useIsTransactionConfirmed(hash)
  const transactionSuccess = transaction?.receipt?.status === 1

  // convert unix time difference to seconds
  const secondsToConfirm = transaction?.confirmedTime
    ? (transaction.confirmedTime - transaction.addedTime) / 1000
    : undefined

  const chainInfo = getChainInfo(chainId)

  return (
    <Wrapper>
      {/* <AutoColumn>
        {!inline && (
          <RowBetween mb="16px" className="l2Content-header">
            <Badge>
              <RowFixed>
                <StyledLogo src={info.logoUrl} style={{ margin: '0 8px 0 0' }} />
                {info.label}
              </RowFixed>
            </Badge>
            <StyledCloseIcon onClick={onDismiss} style={{ marginRight: '8px' }} />
          </RowBetween>
        )} 
      </AutoColumn>*/}
      <LogoContainer>
        {confirmed ? (
          transactionSuccess ? (
            // <CheckCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.accentSuccess} />
            // <AnimatedConfirmation />
            <div className="bg success-bg">
              <AnimatedEntranceSuccessedIcon />
            </div>
          ) : (
            // <AlertCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.accentFailure} />
            <div className="bg fail-bg">
              <AnimatedEntranceFailedIcon />
            </div>
          )
        ) : (
          // <CustomLightSpinner src={Circle} alt="loader" size={inline ? '40px' : '90px'} />
          <div className="bg pending-bg">
            <AnimatedPendingIcon />
          </div>
        )}
      </LogoContainer>
      <StyledConfirmClose className="">
        <StyledCloseIcon2 onClick={onDismiss} data-testid="l2Content-close-icon" />
      </StyledConfirmClose>
      <Box mt="186px" p="0 8px">
        <AutoColumn gap="8px" justify="center">
          <ThemedText.TextPrimary fontSize={16} fontWeight={700} textAlign="center">
            {!hash ? (
              <Trans>Confirm transaction in wallet</Trans>
            ) : !confirmed ? (
              <Trans>Transaction Submitted</Trans>
            ) : transactionSuccess ? (
              <ThemedText.TextPrimary fontSize={16} fontWeight={700} color="long">
                <Trans>Transaction Successful</Trans>
              </ThemedText.TextPrimary>
            ) : (
              <ThemedText.TextPrimary fontSize={16} fontWeight={700} color="short">
                <Trans>Error</Trans>
              </ThemedText.TextPrimary>
            )}
          </ThemedText.TextPrimary>
          {transaction ? (
            <ThemedText.TextPrimary fontSize={16} fontWeight={700} textAlign="center">
              <TransactionSummary info={transaction.info} />
            </ThemedText.TextPrimary>
          ) : (
            <ThemedText.TextSecondary fontSize={14} fontWeight={400} textAlign="center">
              {pendingText}
            </ThemedText.TextSecondary>
          )}

          {secondsToConfirm && (
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Transaction completed in {secondsToConfirm} seconds 🎉</Trans>
            </ThemedText.TextSecondary>
          )}
        </AutoColumn>

        {chainId && hash ? (
          <StyledChainInfo>
            <RowBetween>
              <RowFixed align="center" data-testid="confirmation-modal-chain-info" gap="sm">
                <img src={chainInfo?.logoUrl} width="24px" height="24px" style={{ borderRadius: '50%' }} />
                <ThemedText.TextSecondary fontSize={12}>{chainInfo?.label}</ThemedText.TextSecondary>
              </RowFixed>
              <Box display="flex" alignItems="center" gap="6px">
                <ExternalLink
                  href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
                  style={{ textDecoration: 'underline' }}
                >
                  <ThemedText.BodySmall fontSize={12} color={theme.claimBtn}>
                    <Trans>View on Explorer</Trans>
                  </ThemedText.BodySmall>
                </ExternalLink>
                <ExplorerIcon />
              </Box>
            </RowBetween>
          </StyledChainInfo>
        ) : (
          <ButtonPrimary onClick={onDismiss} style={{ margin: '16px 0 0 0' }}>
            {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
          </ButtonPrimary>
        )}
      </Box>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  txError?: string
  onDismiss: () => void
  hash?: string
  reviewContent: () => ReactNode
  attemptingTxn: boolean
  pendingText: ReactNode
  currencyToAdd?: Currency
  width?: string
}

export default function TransactionConfirmationModal({
  isOpen,
  txError,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  reviewContent,
  currencyToAdd,
  width,
}: ConfirmationModalProps) {
  const { chainId } = useActiveChainId()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal
      isOpen={isOpen}
      $scrollOverlay={true}
      onDismiss={onDismiss}
      maxHeight={90}
      width={attemptingTxn || hash || txError ? '400px' : width ?? '800px'}
      maxWidth={800}
    >
      {isL2ChainId(chainId) && (hash || attemptingTxn) ? (
        <L2Content chainId={chainId} hash={hash} onDismiss={onDismiss} pendingText={pendingText} />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : txError ? (
        <TransactionErrorContent txError={txError} onDismiss={onDismiss} />
      ) : (
        reviewContent()
      )}
    </Modal>
  )
}

interface ConfirmationModalProps2222 {
  isOpen: boolean
  txError?: string
  onDismiss: () => void
  hash?: string
  reviewContent: ReactNode
  attemptingTxn: boolean
  pendingText: ReactNode
  currencyToAdd?: Currency
  width?: string
}

export function TransactionConfirmationModal22222({
  isOpen,
  txError,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  reviewContent,
  currencyToAdd,
  width,
}: ConfirmationModalProps2222) {
  const { chainId } = useActiveChainId()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal
      isOpen={isOpen}
      $scrollOverlay={true}
      onDismiss={onDismiss}
      maxHeight={90}
      width={attemptingTxn || hash || txError ? '400px' : width ?? '800px'}
      maxWidth={800}
    >
      {isL2ChainId(chainId) && (hash || attemptingTxn) ? (
        <L2Content chainId={chainId} hash={hash} onDismiss={onDismiss} pendingText={pendingText} />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : txError ? (
        <TransactionErrorContent txError={txError} onDismiss={onDismiss} />
      ) : (
        reviewContent
      )}
    </Modal>
  )
}
