import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const LearnMoreBox = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.moreBorder};
  background-image: ${({ theme }) => theme.textMore};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
`

export default function LearnMore() {
  return (
    <LearnMoreBox>
      <ThemedText.BodySmall fontSize={12} fontWeight={700}>
        <Trans>Learn More</Trans>
      </ThemedText.BodySmall>
    </LearnMoreBox>
  )
}
