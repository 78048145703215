import { LoadingRows as BaseLoadingRows } from 'components/Loader/styled'
import styled from 'styled-components/macro'

import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

export const PaddedColumn = styled(AutoColumn)`
  padding: 16px;
  width: 100%;
`

export const MenuItem = styled(RowBetween)<{ dim?: boolean }>`
  padding: 5px 16px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  border-bottom: 1px dashed ${({ theme }) => theme.backgroundInteractive};
  :hover {
    background-color: ${({ theme }) => theme.backgroundHover};
  }
  opacity: ${({ disabled, selected, dim }) => (dim || disabled || selected ? 0.4 : 1)};
`

const ICON_SIZE = '16px'
export const SearchInput = styled.input<{ width?: string }>`
  background: no-repeat scroll 8px 8px;
  background-image: url(${({ theme }) => theme.searchIcon});
  background-size: 16px 16px;
  background-position: 8px center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  height: 36px;
  width: ${({ width }) => (width ? width : '100%')};
  max-width: 100%;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  border-style: solid;
  border: 1px solid ${({ theme }) => theme.sectionBorder};
  -webkit-appearance: none;
  transition: border 100ms;

  :focus {
    border: 1px solid ${({ theme }) => theme.activeBorder};
    outline: none;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.activeBorder};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    background-image: url(${({ theme }) => theme.closeIcon});
    margin-right: 10px;
    background-size: ${ICON_SIZE} ${ICON_SIZE};
    cursor: pointer;
  }
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.backgroundOutline};
`

export const LoadingRows = styled(BaseLoadingRows)`
  grid-column-gap: 0.5em;
  grid-template-columns: repeat(12, 1fr);
  max-width: 960px;
  padding: 12px 20px;

  & > div:nth-child(4n + 1) {
    grid-column: 1 / 8;
    height: 1em;
    margin-bottom: 0.25em;
  }
  & > div:nth-child(4n + 2) {
    grid-column: 12;
    height: 1em;
    margin-top: 0.25em;
  }
  & > div:nth-child(4n + 3) {
    grid-column: 1 / 4;
    height: 0.75em;
  }
`
