import Column from 'components/Column'
import { RowBetween } from 'components/Row'
import styled from 'styled-components/macro'

export const DropdownWrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  border-radius: 16px;
  padding: 12px 16px;
`
export const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

export const RotatingArrow = styled.span<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
  width: 16px;
  height: 16px;
`

export const SwapDetailsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.grids.md};
`
