import { Trans } from '@lingui/macro'
import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { HeaderCellWrapper } from 'components/Tokens/TokenTable/TokenRow'
import { useAtomValue } from 'jotai/utils'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { TokenSortAscendingAtom, TokenSortMethod, TokenSortMethodAtom, useSetTokenSortMethod } from '../state'

export default function SortCell({
  category,
  justify,
}: {
  category: TokenSortMethod // TODO: change this to make it work for trans
  justify?: string
}) {
  const theme = useTheme()
  const sortAscending = useAtomValue(TokenSortAscendingAtom)
  const handleSortCategory = useSetTokenSortMethod(category)
  const sortMethod = useAtomValue(TokenSortMethodAtom)

  return (
    <HeaderCellWrapper onClick={handleSortCategory} justify={justify}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowChangeUp width={16} height={16} color={theme.textSecondary} />
          ) : (
            <ArrowChangeDown width={16} height={16} color={theme.textTertiary} />
          )}
        </>
      )}
      <ThemedText.TextTertiary fontSize={12} fontWeight={700} className="item">
        <Trans>{category}</Trans>
      </ThemedText.TextTertiary>
    </HeaderCellWrapper>
  )
}
