import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { Position } from '@uniswap/v3-sdk'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { Break } from 'components/earn/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RateToggle2 } from 'components/RateToggle'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { DoubleArrow, PriceCard } from 'pages/Pool/StyledPosition'
import { ReactNode, useCallback, useState } from 'react'
import { Bound } from 'state/mint/v3/actions'
import { ThemedText } from 'theme'
import { formatTickPrice } from 'utils/formatTickPrice'
import { unwrappedToken } from 'utils/unwrappedToken'

function CurrentPriceCard({
  price,
  currencyQuote,
  currencyBase,
  handleRateChange,
}: {
  price?: any
  currencyQuote?: Currency
  currencyBase?: Currency
  handleRateChange?: any
}) {
  if (!currencyQuote || !currencyBase) {
    return null
  }

  const tokenA = currencyBase?.wrapped
  const tokenB = currencyQuote?.wrapped
  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return (
    <Column>
      <Row gap="sm" justify="flex-end">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Price</Trans>
        </ThemedText.TextSecondary>
        <RateToggle2 currencyA={currencyBase} currencyB={currencyQuote} handleRateToggle={handleRateChange} />
      </Row>
      <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
        {price} {isSorted ? currencyBase?.symbol : currencyQuote?.symbol}
      </ThemedText.TextPrimary>
    </Column>
  )
}

export const PositionPreview = ({
  position,
  baseCurrencyDefault,
  ticksAtLimit,
}: {
  position: Position
  title?: ReactNode
  inRange: boolean
  baseCurrencyDefault?: Currency
  ticksAtLimit: { [bound: string]: boolean | undefined }
}) => {
  const currency0 = unwrappedToken(position.pool.token0)
  const currency1 = unwrappedToken(position.pool.token1)

  // track which currency should be base
  const [baseCurrency, setBaseCurrency] = useState(
    baseCurrencyDefault
      ? baseCurrencyDefault === currency0
        ? currency0
        : baseCurrencyDefault === currency1
        ? currency1
        : currency0
      : currency0
  )

  const sorted = baseCurrency === currency0
  const quoteCurrency = sorted ? currency1 : currency0

  const price = sorted ? position.pool.priceOf(position.pool.token0) : position.pool.priceOf(position.pool.token1)

  const priceLower = sorted ? position.token0PriceLower : position.token0PriceUpper.invert()
  const priceUpper = sorted ? position.token0PriceUpper : position.token0PriceLower.invert()

  const handleRateChange = useCallback(() => {
    setBaseCurrency(quoteCurrency)
  }, [quoteCurrency])

  return (
    <AutoColumn gap="xmd" style={{ marginTop: '0.5rem' }}>
      <RowBetween style={{ marginBottom: '0.5rem' }}>
        <RowFixed>
          <DoubleCurrencyLogo
            currency0={currency0 ?? undefined}
            currency1={currency1 ?? undefined}
            size={30}
            margin={true}
          />
          <ThemedText.TextPrimary fontSize="36px" fontWeight={800} ml="12px">
            {currency0?.symbol} / {currency1?.symbol}
          </ThemedText.TextPrimary>
        </RowFixed>
        <CurrentPriceCard
          price={price?.toSignificant(5)}
          currencyQuote={sorted ? currency1 : currency0}
          currencyBase={sorted ? currency0 : currency1}
          handleRateChange={handleRateChange}
        />
      </RowBetween>
      <PriceCard>
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Min Price</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700}>
          {formatTickPrice({
            price: priceLower,
            atLimit: ticksAtLimit,
            direction: Bound.LOWER,
          })}
        </ThemedText.TextSecondary>
        <DoubleArrow>————</DoubleArrow>
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Max Price</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextSecondary fontWeight={700}>
          {formatTickPrice({
            price: priceUpper,
            atLimit: ticksAtLimit,
            direction: Bound.UPPER,
          })}
        </ThemedText.TextSecondary>
      </PriceCard>
      <LightCard>
        <AutoColumn gap="xmd">
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency0} />
              <ThemedText.DeprecatedLabel ml="8px">{currency0?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{position.amount0.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <CurrencyLogo currency={currency1} />
              <ThemedText.DeprecatedLabel ml="8px">{currency1?.symbol}</ThemedText.DeprecatedLabel>
            </RowFixed>
            <RowFixed>
              <ThemedText.DeprecatedLabel mr="8px">{position.amount1.toSignificant(4)}</ThemedText.DeprecatedLabel>
            </RowFixed>
          </RowBetween>
          <Break />
          <RowBetween>
            <ThemedText.DeprecatedLabel>
              <Trans>Fee Tier</Trans>
            </ThemedText.DeprecatedLabel>
            <ThemedText.DeprecatedLabel>
              <Trans>{position?.pool?.fee / 10000}%</Trans>
            </ThemedText.DeprecatedLabel>
          </RowBetween>
        </AutoColumn>
      </LightCard>
    </AutoColumn>
  )
}
