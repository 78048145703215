import { useAtom } from 'jotai'
import { tradeTabAtom } from 'pages/Trade/navState'
import Slider from 'rc-slider'
import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'

const StyledSlider = styled(Slider)`
  cursor: pointer;
`

export default function Leverage({ percent, onSliderChange }: any) {
  const theme = useTheme()

  const [navTab, setNavTab] = useAtom(tradeTabAtom)

  const [trackBg, setTrackBg] = useState<any>(theme.accentAction)
  useEffect(() => {
    if (navTab == 1) {
      setTrackBg(theme.long)
    } else if (navTab == 2) {
      setTrackBg(theme.short)
    }
  }, [navTab, theme.long, theme.short])

  return (
    <StyledSlider
      defaultValue={[1.1, 50]}
      min={1.1}
      max={50}
      // range
      step={0.1}
      value={percent}
      trackStyle={{
        height: '6px',
        backgroundColor: `${trackBg}`,
      }}
      handleStyle={{
        width: '8px',
        height: '20px',
        top: '3px',
        backgroundColor: `${theme.sliderThumb}`,
        borderRadius: '4px',
        border: 'none',
        boxShadow: 'none',
      }}
      railStyle={{
        height: '6px',
        backgroundColor: `rgba(0,0,0,0.05)`,
      }}
      onChange={onSliderChange}
    />
  )
}
