export enum ChainId {
  ARBITRUM_ONE = 42161,
  GOERLI = 5,
  ARBITRUM_SEPOLIA = 421614,
  BLAST_TEST = 168587773,
  BLAST_MAIN = 81457,
  BNB =56
}

export const SUPPORTED_CHAINS = [
  ChainId.ARBITRUM_ONE,
  ChainId.GOERLI,
  ChainId.ARBITRUM_SEPOLIA,
  ChainId.BLAST_TEST,
  ChainId.BLAST_MAIN,
  ChainId.BNB
] as const
export type SupportedChainsType = (typeof SUPPORTED_CHAINS)[number]

export const UniWalletSupportedChains = []

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.ARBITRUM_SEPOLIA]: 'ARBITRUM_SEPOLIA',
  [ChainId.BLAST_TEST]: 'BLAST_TEST',
  [ChainId.BLAST_MAIN]: 'BLAST',
  [ChainId.BNB]:'BSC'
} as const

export function isSupportedChain(chainId: number | null | undefined | ChainId): chainId is SupportedChainsType {
  return !!chainId && SUPPORTED_CHAINS.indexOf(chainId) !== -1 && true
}

export function asSupportedChain(chainId: number | null | undefined | ChainId): SupportedChainsType | undefined {
  return isSupportedChain(chainId) ? chainId : undefined
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [ChainId.ARBITRUM_ONE,] as const

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [] as const

export const TESTNET_CHAIN_IDS = [
  ChainId.GOERLI,
  ChainId.ARBITRUM_SEPOLIA,
  ChainId.BLAST_TEST,
  ChainId.BLAST_MAIN,
  ChainId.BNB
] as const

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [ChainId.GOERLI, ChainId.BNB] as const

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_SEPOLIA,
  ChainId.BLAST_TEST,
  ChainId.BLAST_MAIN,
] as const

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number]

/**
 * Get the priority of a chainId based on its relevance to the user.
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export function getChainPriority(chainId: ChainId): number {
  switch (chainId) {
    case ChainId.ARBITRUM_ONE:
    case ChainId.BLAST_MAIN:
      return 2
    case ChainId.BLAST_TEST:
      return 3
    case ChainId.ARBITRUM_SEPOLIA:
      return 4
    case ChainId.GOERLI:
      return 6
    default:
      return 7
  }
}

export function isUniswapXSupportedChain() {
  // return chainId === ChainId.MAINNET
  return false
}
