import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'

import store from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.MAINNET]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [ChainId.ARBITRUM_ONE]: 'https://api.thegraph.com/subgraphs/name/metaverseblock/roguex_v2_arbmain_beta2',
  [ChainId.OPTIMISM]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
  [ChainId.POLYGON]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  [ChainId.CELO]: 'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo',
  [ChainId.BNB]: 'https://api.roguex.io/bscmainnet/graphdata_v2',
  [ChainId.AVALANCHE]: 'https://api.thegraph.com/subgraphs/name/lynnshaoyu/uniswap-v3-avax',
  [ChainId.GOERLI]: 'https://api.thegraph.com/subgraphs/name/metaverseblock/roguex_v2_arbtest',
  [421614]: 'https://api.thegraph.com/subgraphs/name/metaverseblock/roguex_v2_arbtest_xia',
  [168587773]: 'https://api.studio.thegraph.com/query/42478/blast_test_v33/version/latest',
  [81457]: 'https://api.roguex.io/graphdata_v2',
}


const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[56] })
// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  const chainId = store.getState().application.chainId
  // add the authorization to the headers
  operation.setContext(() => ({
    uri:
      chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[56],
  }))

  return forward(operation)
})


export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware,httpLink ),
})
