import { ParentSize } from '@visx/responsive'
import { ChartContainer, LoadingChart } from 'components/Tokens/TokenDetails/Skeleton'
import { PricePoint } from 'graphql/data/util'
import { TimePeriod } from 'graphql/data/util'
import { useAtomValue } from 'jotai/utils'
import { pageTimePeriodAtom } from 'pages/TokenDetails'
import { startTransition, Suspense, useMemo } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'

import { PriceChart } from './PriceChart'
import TimePeriodSelector from './TimeSelector'
export type OnChangeTimePeriod = (t: TimePeriod) => void
// const DATASSS = [
//   {
//     timestamp: 1695005381,
//     value: 1.0000604703825346,
//   },
//   {
//     timestamp: 1695005981,
//     value: 1.000186612392112,
//   },
//   {
//     timestamp: 1695006581,
//     value: 1.0020655376767118,
//   },
//   {
//     timestamp: 1695007181,
//     value: 1.0019380467999666,
//   },
//   {
//     timestamp: 1695007781,
//     value: 1.0011624182133532,
//   },
//   {
//     timestamp: 1695008381,
//     value: 1.0000386499137692,
//   },
//   {
//     timestamp: 1695008981,
//     value: 1.0000140050475383,
//   },
//   {
//     timestamp: 1695009582,
//     value: 1.0004330747277317,
//   },
//   {
//     timestamp: 1695010181,
//     value: 1.0002861674235108,
//   },
//   {
//     timestamp: 1695010781,
//     value: 1.0000470132743005,
//   },
//   {
//     timestamp: 1695011381,
//     value: 1.0004765335068915,
//   },
//   {
//     timestamp: 1695011981,
//     value: 1.0011942323824339,
//   },
//   {
//     timestamp: 1695012581,
//     value: 1.001252132051681,
//   },
//   {
//     timestamp: 1695013181,
//     value: 1.0003980330009978,
//   },
//   {
//     timestamp: 1695013781,
//     value: 0.9999667996180284,
//   },
//   {
//     timestamp: 1695014381,
//     value: 0.998292037516759,
//   },
//   {
//     timestamp: 1695014981,
//     value: 0.9984054894780726,
//   },
//   {
//     timestamp: 1695015581,
//     value: 0.9973990140424038,
//   },
//   {
//     timestamp: 1695016181,
//     value: 0.9987566986570995,
//   },
//   {
//     timestamp: 1695016781,
//     value: 0.9997437205052748,
//   },
//   {
//     timestamp: 1695017381,
//     value: 0.9996720673826276,
//   },
//   {
//     timestamp: 1695017981,
//     value: 0.9993608266815002,
//   },
//   {
//     timestamp: 1695018581,
//     value: 0.9988761803140063,
//   },
//   {
//     timestamp: 1695019181,
//     value: 0.9994412817474724,
//   },
//   {
//     timestamp: 1695019781,
//     value: 0.9990748143925162,
//   },
//   {
//     timestamp: 1695020381,
//     value: 1.0007669059735351,
//   },
//   {
//     timestamp: 1695020981,
//     value: 1.0001355709012625,
//   },
//   {
//     timestamp: 1695021581,
//     value: 0.9992328705142093,
//   },
//   {
//     timestamp: 1695022181,
//     value: 0.9983368072762048,
//   },
//   {
//     timestamp: 1695022781,
//     value: 0.9997293625061315,
//   },
//   {
//     timestamp: 1695023381,
//     value: 1.0003276006002704,
//   },
//   {
//     timestamp: 1695023981,
//     value: 0.9999731789186189,
//   },
//   {
//     timestamp: 1695024581,
//     value: 1.0004124801779661,
//   },
//   {
//     timestamp: 1695025181,
//     value: 1.0005038376867823,
//   },
//   {
//     timestamp: 1695025781,
//     value: 1.0006906713022439,
//   },
//   {
//     timestamp: 1695026381,
//     value: 1.0010284924998762,
//   },
//   {
//     timestamp: 1695026981,
//     value: 1.0010319915526376,
//   },
//   {
//     timestamp: 1695027581,
//     value: 1.0005619691877605,
//   },
//   {
//     timestamp: 1695028181,
//     value: 0.9989891963312875,
//   },
//   {
//     timestamp: 1695028781,
//     value: 0.998410438324292,
//   },
//   {
//     timestamp: 1695029381,
//     value: 0.9994207182022962,
//   },
//   {
//     timestamp: 1695029981,
//     value: 0.9990591228336131,
//   },
//   {
//     timestamp: 1695030581,
//     value: 0.9976096486728957,
//   },
//   {
//     timestamp: 1695031181,
//     value: 0.998813895583137,
//   },
//   {
//     timestamp: 1695031781,
//     value: 0.9981355382455507,
//   },
//   {
//     timestamp: 1695032381,
//     value: 0.9976606959829436,
//   },
//   {
//     timestamp: 1695032981,
//     value: 0.9980754187331553,
//   },
//   {
//     timestamp: 1695033581,
//     value: 0.997703047040242,
//   },
//   {
//     timestamp: 1695034181,
//     value: 0.9985970807977216,
//   },
//   {
//     timestamp: 1695034781,
//     value: 0.9994733601789701,
//   },
//   {
//     timestamp: 1695035381,
//     value: 0.9982962390004441,
//   },
//   {
//     timestamp: 1695035981,
//     value: 0.9995325109214247,
//   },
//   {
//     timestamp: 1695036581,
//     value: 0.9977940133918158,
//   },
//   {
//     timestamp: 1695037181,
//     value: 1.0007602853248747,
//   },
//   {
//     timestamp: 1695037781,
//     value: 1.0000559984487947,
//   },
//   {
//     timestamp: 1695038381,
//     value: 0.9987976367873976,
//   },
//   {
//     timestamp: 1695038981,
//     value: 0.9997491475892402,
//   },
//   {
//     timestamp: 1695039581,
//     value: 0.9993625887309624,
//   },
//   {
//     timestamp: 1695040181,
//     value: 0.9983581869072676,
//   },
//   {
//     timestamp: 1695040781,
//     value: 0.9979884957609085,
//   },
//   {
//     timestamp: 1695041381,
//     value: 1.0000081232100724,
//   },
//   {
//     timestamp: 1695041981,
//     value: 0.9987204890453459,
//   },
//   {
//     timestamp: 1695042581,
//     value: 0.9999939827747115,
//   },
//   {
//     timestamp: 1695043181,
//     value: 1.0014583662731846,
//   },
//   {
//     timestamp: 1695043781,
//     value: 1.0022136785122113,
//   },
//   {
//     timestamp: 1695044381,
//     value: 1.0016343721467225,
//   },
//   {
//     timestamp: 1695044981,
//     value: 1.0026563746978143,
//   },
//   {
//     timestamp: 1695045581,
//     value: 1.000413435267151,
//   },
//   {
//     timestamp: 1695046181,
//     value: 1.0018490451596407,
//   },
//   {
//     timestamp: 1695046781,
//     value: 1.0005144669793369,
//   },
//   {
//     timestamp: 1695047381,
//     value: 0.9991942977268641,
//   },
//   {
//     timestamp: 1695047981,
//     value: 0.9995995488328078,
//   },
//   {
//     timestamp: 1695048581,
//     value: 0.9992221313779825,
//   },
//   {
//     timestamp: 1695049181,
//     value: 0.9984376668556941,
//   },
//   {
//     timestamp: 1695049781,
//     value: 0.9985117006424137,
//   },
//   {
//     timestamp: 1695050381,
//     value: 0.9980374323015834,
//   },
//   {
//     timestamp: 1695050981,
//     value: 0.9982288143140353,
//   },
//   {
//     timestamp: 1695051581,
//     value: 0.9991070371136535,
//   },
//   {
//     timestamp: 1695052181,
//     value: 0.998934838643306,
//   },
//   {
//     timestamp: 1695052781,
//     value: 1.0008664092299917,
//   },
//   {
//     timestamp: 1695053381,
//     value: 1.0004297211945392,
//   },
//   {
//     timestamp: 1695053981,
//     value: 1.0020928258389565,
//   },
//   {
//     timestamp: 1695054581,
//     value: 1.0019925511421943,
//   },
//   {
//     timestamp: 1695055181,
//     value: 1.0019852727438838,
//   },
//   {
//     timestamp: 1695055781,
//     value: 0.9995982198461595,
//   },
//   {
//     timestamp: 1695056381,
//     value: 1.000001100214392,
//   },
//   {
//     timestamp: 1695056981,
//     value: 1.001008621486978,
//   },
//   {
//     timestamp: 1695057581,
//     value: 1.0024720845448898,
//   },
//   {
//     timestamp: 1695058181,
//     value: 1.0019803994748058,
//   },
//   {
//     timestamp: 1695058781,
//     value: 0.9998295797981145,
//   },
//   {
//     timestamp: 1695059381,
//     value: 0.9995177304053391,
//   },
//   {
//     timestamp: 1695059981,
//     value: 1.0006744236940168,
//   },
//   {
//     timestamp: 1695060581,
//     value: 0.9990871513928221,
//   },
//   {
//     timestamp: 1695061181,
//     value: 0.9982778455328667,
//   },
//   {
//     timestamp: 1695061781,
//     value: 1.0016801232879502,
//   },
//   {
//     timestamp: 1695062381,
//     value: 1.0012603139384875,
//   },
//   {
//     timestamp: 1695062981,
//     value: 1.0010381303788038,
//   },
//   {
//     timestamp: 1695063581,
//     value: 1.0012872547184382,
//   },
//   {
//     timestamp: 1695064181,
//     value: 0.9989098069324618,
//   },
//   {
//     timestamp: 1695064781,
//     value: 1.0000759059892526,
//   },
//   {
//     timestamp: 1695065381,
//     value: 0.9994924310237434,
//   },
//   {
//     timestamp: 1695065981,
//     value: 0.9981575117973173,
//   },
//   {
//     timestamp: 1695066581,
//     value: 0.9985414659383617,
//   },
//   {
//     timestamp: 1695067181,
//     value: 0.9996273825324746,
//   },
//   {
//     timestamp: 1695067781,
//     value: 1.0009059756916543,
//   },
//   {
//     timestamp: 1695068381,
//     value: 1.0007578727554516,
//   },
//   {
//     timestamp: 1695068981,
//     value: 1.0013125236576512,
//   },
//   {
//     timestamp: 1695069581,
//     value: 1.0012349602223143,
//   },
//   {
//     timestamp: 1695070181,
//     value: 1.001476832364133,
//   },
//   {
//     timestamp: 1695070781,
//     value: 1.0015854240840527,
//   },
//   {
//     timestamp: 1695071381,
//     value: 1.0023918826374745,
//   },
//   {
//     timestamp: 1695071981,
//     value: 1.0015351581506005,
//   },
//   {
//     timestamp: 1695072581,
//     value: 1.0015271256178073,
//   },
//   {
//     timestamp: 1695073181,
//     value: 1.0015102239311295,
//   },
//   {
//     timestamp: 1695073781,
//     value: 1.001003042069915,
//   },
//   {
//     timestamp: 1695074381,
//     value: 1.00076876584535,
//   },
//   {
//     timestamp: 1695074981,
//     value: 1.000247939632859,
//   },
//   {
//     timestamp: 1695075581,
//     value: 1.0003703450666221,
//   },
//   {
//     timestamp: 1695076181,
//     value: 1.0003426695324356,
//   },
//   {
//     timestamp: 1695076781,
//     value: 1.0011102484470067,
//   },
//   {
//     timestamp: 1695077381,
//     value: 1.0008440490358348,
//   },
//   {
//     timestamp: 1695077981,
//     value: 0.9994735910368784,
//   },
//   {
//     timestamp: 1695078581,
//     value: 0.9996804601252192,
//   },
//   {
//     timestamp: 1695079181,
//     value: 1.0007145394545487,
//   },
//   {
//     timestamp: 1695079781,
//     value: 1.0013881803591678,
//   },
//   {
//     timestamp: 1695080381,
//     value: 1.0023953366029625,
//   },
//   {
//     timestamp: 1695080981,
//     value: 1.002478756992736,
//   },
//   {
//     timestamp: 1695081581,
//     value: 1.0022347583043218,
//   },
//   {
//     timestamp: 1695082181,
//     value: 1.0016096195393733,
//   },
//   {
//     timestamp: 1695082781,
//     value: 1.0023427521426977,
//   },
//   {
//     timestamp: 1695083381,
//     value: 1.0022015807073703,
//   },
//   {
//     timestamp: 1695083981,
//     value: 1.000825505169573,
//   },
//   {
//     timestamp: 1695084581,
//     value: 1.0015840142242605,
//   },
//   {
//     timestamp: 1695085181,
//     value: 1.0009694648493808,
//   },
//   {
//     timestamp: 1695085781,
//     value: 1.0007742262140922,
//   },
//   {
//     timestamp: 1695086381,
//     value: 0.9999516110741072,
//   },
//   {
//     timestamp: 1695086981,
//     value: 0.9996966981073546,
//   },
//   {
//     timestamp: 1695087581,
//     value: 0.9997413268957712,
//   },
//   {
//     timestamp: 1695088181,
//     value: 0.9994824170006273,
//   },
//   {
//     timestamp: 1695088781,
//     value: 0.9999014860486142,
//   },
//   {
//     timestamp: 1695089381,
//     value: 0.9994464992483745,
//   },
//   {
//     timestamp: 1695089981,
//     value: 0.9979447365901047,
//   },
//   {
//     timestamp: 1695090581,
//     value: 0.9978395759946604,
//   },
//   {
//     timestamp: 1695091181,
//     value: 0.9978746808059468,
//   },
//   {
//     timestamp: 1695094133.534,
//     value: 0.9974706985536375,
//   },
// ]

function isPricePoint(p: PricePoint | null): p is PricePoint {
  return p !== null
}
export default function ChartSection2({
  tokenPriceQuery,
  onChangeTimePeriod,
  priceHover,
}: {
  tokenPriceQuery?: PricePoint[]
  onChangeTimePeriod: OnChangeTimePeriod
  priceHover: (data: PricePoint) => void
}) {
  if (!tokenPriceQuery) {
    return <LoadingChart />
  }

  // if (tokenPriceQuery.length <= 4) {
  //   return (
  //     <Box height="100%" width="100%" color="#FFF" display="flex" alignItems="center" justifyContent="center">
  //       No Data
  //     </Box>
  //   )
  // }

  return (
    <Suspense fallback={<LoadingChart />}>
      <ChartContainer>
        <Chart tokenPriceQuery={tokenPriceQuery} priceHover={priceHover} onChangeTimePeriod={onChangeTimePeriod} />
      </ChartContainer>
    </Suspense>
  )
}

function Chart({
  tokenPriceQuery,
  onChangeTimePeriod,
  priceHover,
}: {
  tokenPriceQuery: any[]
  onChangeTimePeriod: OnChangeTimePeriod
  priceHover: (data: PricePoint) => void
}) {
  const { isToken0 } = useTradeSWAPState()
  const prices = useMemo(() => {
    return tokenPriceQuery.map((x) => {
      return {
        ...x,
        value: isToken0 ? Number(x.token1Ratio) : Number(x.token0Ratio),
        timestamp: Number(x.timestamp),
        twap: isToken0 ? Number(x.token1Twap) : Number(x.token0Twap),
      }
    })
  }, [isToken0, tokenPriceQuery])
  // const prices = usePriceHistory(tokenPriceQuery)
  // Initializes time period to global & maintain separate time period for subsequent changes

  const timePeriod = useAtomValue(pageTimePeriodAtom)

  return (
    <ChartContainer data-testid="chart-container">
      <TimePeriodSelector
        currentTimePeriod={timePeriod}
        onTimeChange={(t: TimePeriod) => {
          startTransition(() => onChangeTimePeriod(t))
        }}
      />
      <ParentSize>
        {({ width }) => (
          <PriceChart priceHover={priceHover} prices={prices} width={width} height={436} timePeriod={timePeriod} />
        )}
      </ParentSize>
    </ChartContainer>
  )
}
