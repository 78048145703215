import { Trans } from '@lingui/macro'
import { AxisBottom, AxisRight, TickFormatter } from '@visx/axis'
import { localPoint } from '@visx/event'
import { EventType } from '@visx/event/lib/types'
import { GlyphCircle } from '@visx/glyph'
import { Line } from '@visx/shape'
import AnimatedInLineChart from 'components/Charts/AnimatedInLineChart'
import FadedInLineChart from 'components/Charts/FadeInLineChart'
import { bisect, curveCardinal, NumberValue, scaleLinear, timeDay, timeHour, timeMinute, timeMonth } from 'd3'
import { PricePoint } from 'graphql/data/util'
import { TimePeriod } from 'graphql/data/util'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowDownRight, ArrowUpRight, TrendingUp } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { BN } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import {
  dayHourFormatter,
  hourFormatter,
  monthDayFormatter,
  monthTickFormatter,
  monthYearDayFormatter,
  weekFormatter,
} from 'utils/formatChartTimes'

const DATA_EMPTY = { value: 0, timestamp: 0, twap: 0 }
export function getPriceBounds(pricePoints: PricePoint[]): [number, number] {
  const prices = pricePoints.map((x) => x.value)
  const pricestWAP = pricePoints.map((x) => x.twap)
  const min = Math.min(...[...pricestWAP, ...prices])
  const max = Math.max(...[...pricestWAP, ...prices])
  return [min, max]
}

function getPriceBoundsc(pricePoints: PricePoint[]) {
  const prices = pricePoints.map((x) => x.value)
  const pricestWAP = pricePoints.map((x) => x.twap)
  const min = Math.min(...[...pricestWAP, ...prices])
  const max = Math.max(...[...pricestWAP, ...prices])

  const tick = Math.floor((max - min) / 8)
  const maxtick = Math.floor(max / tick) * tick + tick
  const mintick = Math.floor(min / tick) * tick - tick
  return [mintick, maxtick]
}

export function getPriceBoundstwap(pricePoints: PricePoint[]): [number, number] {
  const prices = pricePoints.map((x) => x.twap)
  debugger
  const min = Math.min(...prices)
  const max = Math.max(...prices)
  return [min, max]
}

// function getPirceALL(pricePoints: PricePoint[]) {
//   const bound = getPriceBoundsc(pricePoints)
//   const boundtwap = getPriceBoundstwap(pricePoints)

//   let min = bound[0] ?? 0

//   let max = bound[1] ?? 0

//   if (bound[0] && bound[1] && boundtwap[0] && boundtwap[1]) {
//     if (boundtwap[0] < bound[0]) {
//       min = boundtwap[0]
//     }
//     if (boundtwap[1] > bound[1]) {
//       max = boundtwap[1]
//     }
//   }
//   return [min, max]
// }

const StyledUpArrow = styled(ArrowUpRight)`
  color: ${({ theme }) => theme.accentSuccess};
`
const StyledDownArrow = styled(ArrowDownRight)`
  color: ${({ theme }) => theme.accentFailure};
`

export function getDeltaArrow(delta: number | null | undefined, iconSize = 20) {
  // Null-check not including zero
  if (delta === null || delta === undefined) {
    return null
  } else if (Math.sign(delta) < 0) {
    return <StyledDownArrow size={iconSize} key="arrow-down" aria-label="down" />
  }
  return <StyledUpArrow size={iconSize} key="arrow-up" aria-label="up" />
}

function getMax(prev: any, next: any) {
  return Math.max(prev.value ? prev.value : prev, next.value)
}

export function formatDelta(delta: number | null | undefined) {
  // Null-check not including zero
  if (delta === null || delta === undefined || delta === Infinity || isNaN(delta)) {
    return '-'
  }
  const formattedDelta = Math.abs(delta).toFixed(2) + '%'
  return formattedDelta
}

export const DeltaText = styled.span<{ delta?: number }>`
  color: ${({ theme, delta }) =>
    delta !== undefined ? (Math.sign(delta) < 0 ? theme.accentFailure : theme.accentSuccess) : theme.textPrimary};
`

export const TokenPrice = styled.span`
  font-size: 36px;
  line-height: 44px;
`

export const ArrowCell = styled.div`
  padding-right: 3px;
  display: flex;
`

function fixChart(prices: PricePoint[] | undefined | null) {
  if (!prices) return { prices: null, blanks: [], twapprice: null, twapblanks: [] }

  const fixedChart: PricePoint[] = []
  const twapfixedChart: PricePoint[] = []
  const blanks: PricePoint[][] = []
  const twapblanks: PricePoint[][] = []

  let lastValue: PricePoint | undefined = undefined
  for (let i = 0; i < prices.length; i++) {
    if (prices[i].value !== 0) {
      if (fixedChart.length === 0 && i !== 0) {
        blanks.push([{ ...prices[0], value: prices[i].value }, prices[i]])
      }
      lastValue = prices[i]
      fixedChart.push(prices[i])
    }
    if (prices[i].twap !== 0) {
      if (twapfixedChart.length === 0 && i !== 0) {
        twapblanks.push([{ ...prices[0], value: prices[i].twap }, prices[i]])
      }
      lastValue = prices[i]
      twapfixedChart.push(prices[i])
    }
  }

  if (lastValue && lastValue !== prices[prices.length - 1]) {
    blanks.push([lastValue, { ...prices[prices.length - 1], value: lastValue.value }])
    twapblanks.push([lastValue, { ...prices[prices.length - 1], value: lastValue.twap }])
  }

  return { prices: fixedChart, blanks, twapblanks, twapprice: twapfixedChart }
}

const margin = { top: 50, bottom: 48, crosshair: 20 }
const timeOptionsHeight = 44
// const timeOptionsHeight = 44

interface PriceChartProps {
  width: number
  height: number
  prices?: PricePoint[] | null
  timePeriod: TimePeriod
  priceHover?: (data: PricePoint) => void
}

export function PriceChart({ width, height, prices: originalPrices, timePeriod, priceHover }: PriceChartProps) {
  const locale = useActiveLocale()
  const theme = useTheme()
  width = width - 60

  const { prices, blanks, twapblanks, twapprice } = useMemo(
    () =>
      originalPrices && originalPrices.length > 0
        ? fixChart(originalPrices)
        : { prices: null, blanks: [], twapprice: null, twapblanks: [] },
    [originalPrices]
  )

  const chartAvailable = !!prices && prices.length > 0
  const missingPricesMessage = !chartAvailable ? (
    prices?.length === 0 ? (
      <>
        <Trans>Missing price data due to recently low trading volume on Uniswap v3</Trans>
      </>
    ) : (
      <Trans>Missing chart data</Trans>
    )
  ) : null

  // first price point on the x-axis of the current time period's chart
  const startingPrice = originalPrices?.[0] ?? DATA_EMPTY
  // last price point on the x-axis of the current time period's chart
  const endingPrice = originalPrices?.[originalPrices.length - 1] ?? DATA_EMPTY
  const [displayPrice, setDisplayPrice] = useState(startingPrice)

  // set display price to ending price when prices have changed.
  useEffect(() => {
    setDisplayPrice(endingPrice)
    priceHover && priceHover(endingPrice)
  }, [prices, endingPrice, priceHover])
  const [crosshair, setCrosshair] = useState<number | null>(null)

  const graphHeight = height - timeOptionsHeight > 0 ? height - timeOptionsHeight : 0
  const graphInnerHeight = graphHeight - margin.top - margin.bottom > 0 ? graphHeight - margin.top - margin.bottom : 0

  // Defining scales
  // x scale
  const timeScale = useMemo(
    () => scaleLinear().domain([startingPrice.timestamp, endingPrice.timestamp]).range([0, width]),
    [startingPrice, endingPrice, width]
  )
  const priceScale = useMemo(() => {
    return scaleLinear()
      .domain(getPriceBoundsc(originalPrices ?? []))
      .range([graphHeight, 0])
      .nice(4)
  }, [graphHeight, originalPrices])
  // y scale
  const rdScale = useMemo(
    () =>
      scaleLinear()
        .domain(getPriceBounds(originalPrices ?? []))
        .range([graphInnerHeight, 0]),
    [originalPrices, graphInnerHeight]
  )
  const twaprdscale = useMemo(
    () =>
      scaleLinear()
        .domain(getPriceBounds(originalPrices ?? []))
        .range([graphInnerHeight, 0]),
    [originalPrices, graphInnerHeight]
  )

  function tickFormat(
    timePeriod: TimePeriod,
    locale: string
  ): [TickFormatter<NumberValue>, (v: number) => string, NumberValue[]] {
    const offsetTime = (endingPrice.timestamp.valueOf() - startingPrice.timestamp.valueOf()) / 24
    const startDateWithOffset = new Date((startingPrice.timestamp.valueOf() + offsetTime) * 1000)
    const endDateWithOffset = new Date((endingPrice.timestamp.valueOf() - offsetTime) * 1000)
    switch (timePeriod) {
      case TimePeriod.HOUR: {
        const interval = timeMinute.every(5)

        return [
          hourFormatter(locale),
          dayHourFormatter(locale),
          (interval ?? timeMinute)
            .range(startDateWithOffset, endDateWithOffset, interval ? 2 : 10)
            .map((x) => x.valueOf() / 1000),
        ]
      }
      case TimePeriod.DAY:
        return [
          hourFormatter(locale),
          dayHourFormatter(locale),
          timeHour.range(startDateWithOffset, endDateWithOffset, 4).map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.WEEK:
        return [
          weekFormatter(locale),
          dayHourFormatter(locale),
          timeDay.range(startDateWithOffset, endDateWithOffset, 1).map((x) => x.valueOf() / 1000),
        ]
      case TimePeriod.MONTH:
        return [
          monthDayFormatter(locale),
          dayHourFormatter(locale),
          timeDay.range(startDateWithOffset, endDateWithOffset, 7).map((x) => x.valueOf() / 1000),
        ]
      // case TimePeriod.YEAR:
      //   return [
      //     monthTickFormatter(locale),
      //     monthYearDayFormatter(locale),
      //     timeMonth.range(startDateWithOffset, endDateWithOffset, 2).map((x) => x.valueOf() / 1000),
      //   ]
    }
  }

  const handleHover = useCallback(
    (event: Element | EventType) => {
      if (!prices) return

      const { x } = localPoint(event) || { x: 0 }
      const x0 = timeScale.invert(x) // get timestamp from the scalexw
      const index = bisect(
        prices.map((x) => x.timestamp),
        x0,
        1
      )

      const d0 = prices[index - 1]
      const d1 = prices[index]
      let pricePoint = d0

      const hasPreviousData = d1 && d1.timestamp
      if (hasPreviousData) {
        pricePoint = x0.valueOf() - d0.timestamp.valueOf() > d1.timestamp.valueOf() - x0.valueOf() ? d1 : d0
      }

      if (pricePoint) {
        setCrosshair(timeScale(pricePoint.timestamp))

        setDisplayPrice(pricePoint)
        priceHover && priceHover(pricePoint)
      }
    },
    [prices, timeScale, priceHover]
  )

  const resetDisplay = useCallback(() => {
    setCrosshair(null)
    setDisplayPrice(endingPrice)
    priceHover && priceHover(endingPrice)
  }, [endingPrice, priceHover])

  // Resets the crosshair when the time period is changed, to avoid stale UI
  useEffect(() => {
    setCrosshair(null)
  }, [timePeriod])

  const [tickFormatter, crosshairDateFormatter, ticks] = tickFormat(timePeriod, locale)
  //max ticks based on screen size
  const maxTicks = Math.floor(width / 100)

  function calculateTicks(ticks: NumberValue[]) {
    const newTicks = []
    const tickSpacing = Math.floor(ticks.length / maxTicks)
    for (let i = 1; i < ticks.length; i += tickSpacing) {
      newTicks.push(ticks[i])
    }
    return newTicks
  }

  function calculateTickstwap(twap: PricePoint[]) {
    const newTicks: NumberValue[] = []
    const tickSpacing = Math.floor(twap.length / maxTicks)
    for (let i = 1; i < twap.length; i += tickSpacing) {
      newTicks.push(twap[i].twap)
    }
    return newTicks
  }

  const updatedTicks = maxTicks > 0 ? (ticks.length > maxTicks ? calculateTicks(ticks) : ticks) : []

  const crosshairEdgeMax = width * 0.85
  const crosshairAtEdge = !!crosshair && crosshair > crosshairEdgeMax

  // Default curve doesn't look good for the HOUR chart.
  // Higher values make the curve more rigid, lower values smooth the curve but make it less "sticky" to real data points,
  // making it unacceptable for shorter durations / smaller variances.
  const curveTension = timePeriod === TimePeriod.HOUR ? 1 : 0.9

  const getX = useMemo(() => (p: PricePoint) => timeScale(p.timestamp), [timeScale])
  const getY = useMemo(() => (p: PricePoint) => rdScale(p.value), [rdScale])
  const getYY = useMemo(() => (p: PricePoint) => twaprdscale(p.twap), [twaprdscale])
  const curve = useMemo(() => curveCardinal.tension(curveTension), [curveTension])

  // const twaptickFormat = (data: any) => {
  //   return countZeros(data, true) as any
  // }


  const ZeroSpot = useMemo(()=>{
    if(!displayPrice) return
    return BN(displayPrice.value).toFixed()
  },[displayPrice])

  const ZeroTwap = useMemo(()=>{
    if(!displayPrice) return
    return BN(displayPrice.twap).toFixed()
  },[displayPrice])

  return (
    <>
      {/* <ChartHeader data-cy="chart-header"> */}
      {/* {displayPrice.value ? (
          <>
            <TokenPrice>{formatUSDPrice(displayPrice.value)}</TokenPrice>
            <DeltaContainer>
              {formattedDelta}
              <ArrowCell>{arrow}</ArrowCell>
            </DeltaContainer>
          </>
        ) : (
          <>
            <MissingPrice>Price Unavailable</MissingPrice>
            <ThemedText.Caption style={{ color: theme.textTertiary }}>{missingPricesMessage}</ThemedText.Caption>
          </>
        )} */}
      {/* </ChartHeader> */}

      {!chartAvailable ? (
        <MissingPriceChart width={width} height={graphHeight} message={!!displayPrice.value && missingPricesMessage} />
      ) : (
        <svg
          data-cy="price-chart"
          width={width}
          height={graphHeight}
          style={{ minWidth: '100%', borderTop: `1px solid rgba(255, 255, 255, 0.16)` }}
        >
          <AnimatedInLineChart
            data={prices}
            twapprice={twapprice}
            getX={getX}
            getY={getY}
            getYY={getYY}
            marginTop={margin.top}
            curve={curve}
            strokeWidth={2}
          />

          {/* <AxisRight
            scale={priceScale}
            stroke={theme.backgroundOutline}
            tickStroke={theme.backgroundOutline}
            tickFormat={twaptickFormat}
            numTicks={8}
            // hideTicks={true}
            // tickTransform="translate(0 50)"
            // // tickValues={updatedTickstwap}
            left={width + 48}
            tickLabelProps={() => ({
              fill: theme.chartblack54,
              fontSize: 12,
              textAnchor: 'end',
              transform: 'translate(0 -30)',
            })}
          /> */}
          <AxisBottom
            scale={timeScale}
            stroke={theme.backgroundOutline}
            tickFormat={tickFormatter}
            tickStroke={theme.backgroundOutline}
            tickLength={4}
            hideTicks={true}
            tickTransform="translate(0 -5)"
            tickValues={updatedTicks}
            top={graphHeight - 1}
            tickLabelProps={() => ({
              fill: theme.chartblack54,
              fontSize: 12,
              textAnchor: 'middle',
              transform: 'translate(0 -24)',
            })}
          />
          {blanks.map((blank, index) => (
            <FadedInLineChart
              key={index}
              data={blank}
              getX={getX}
              getY={getY}
              marginTop={margin.top}
              curve={curve}
              strokeWidth={2}
              color={theme.textTertiary}
              dashed
            />
          ))}
          {crosshair !== null ? (
            <g>
              <g>
                <rect
                  x={crosshair + (crosshairAtEdge ? -4 : 4)}
                  y={margin.crosshair + 10}
                />
                <text
                  x={crosshair + (crosshairAtEdge ? -4 : 4)}
                  y={margin.crosshair + 10}
                  textAnchor={crosshairAtEdge ? 'end' : 'start'}
                  fontSize={12}
                  
                >
                  {crosshairDateFormatter(displayPrice.timestamp)}
                </text>
              </g>
              <g>
                <rect
                  x={width - 32}
                  y={rdScale(displayPrice.value) + margin.top - 9}
                  width={90}
                  height={17}
                  fill="#0BADF2"
                />

                <text
                  // x={crosshair + (crosshairAtEdge ? -4 : 4)}
                  // y={margin.crosshair + 10}
                  x={width - 32}
                  y={rdScale(displayPrice.value) + margin.top + 4}
                  textAnchor="start"
                  // textAnchor={crosshairAtEdge ? 'end' : 'start'}
                  fontSize={12}
                  width={90}
                  height={17}
                  fill="#181818"
                >
                  Spot&nbsp;
                  {countZeros(ZeroSpot,false,true)}
                </text>
              </g>
              <Line
                from={{ x: 0, y: rdScale(displayPrice.value) + margin.top }}
                to={{ x: width - 32, y: rdScale(displayPrice.value) + margin.top }}
                stroke={theme.chartblack54}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="4,4"
              />
              <g>
                <rect
                  x={width - 32}
                  y={twaprdscale(displayPrice.twap) + margin.top - 9}
                  width={90}
                  height={17}
                  fill="#00D1D1"
                />

                <text
                  x={width - 32}
                  y={twaprdscale(displayPrice.twap) + margin.top + 4}
                  // textAnchor={crosshairAtEdge ? 'end' : 'start'}
                  textAnchor="start"
                  fontSize={12}
                  width={90}
                  height={17}
                  fill="#181818"
                >
                  TWAP&nbsp;
                  {countZeros(ZeroTwap,false,true)}
                </text>
              </g>

              <Line
                from={{ x: 0, y: twaprdscale(displayPrice.twap) + margin.top }}
                to={{ x: width - 52, y: twaprdscale(displayPrice.twap) + margin.top }}
                stroke={theme.chartblack54}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="4,4"
              />

              <Line
                from={{ x: crosshair, y: margin.crosshair }}
                to={{ x: crosshair, y: graphHeight }}
                stroke={theme.chartblack54}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="4,4"
              />


              <GlyphCircle
                left={crosshair}
                top={twaprdscale(displayPrice.twap) + margin.top}
                size={50}
                fill="#00D1D1"
                stroke="#00D1D1"
                strokeWidth={0.5}
              />
              <GlyphCircle
                left={crosshair}
                top={rdScale(displayPrice.value) + margin.top}
                size={50}
                fill="#0BADF2"
                stroke="#0BADF2"
                strokeWidth={0.5}
              />
            </g>
          ) : (
            <AxisBottom
              hideAxisLine={true}
              scale={timeScale}
              stroke={theme.backgroundOutline}
              top={graphHeight - 1}
              hideTicks
            />
          )}
          {!width && (
            // Ensures an axis is drawn even if the width is not yet initialized.
            <line
              x1={0}
              y1={graphHeight - 1}
              x2="100%"
              y2={graphHeight - 1}
              fill="transparent"
              shapeRendering="crispEdges"
              stroke={theme.backgroundOutline}
              strokeWidth={1}
            />
          )}
          <rect
            x={0}
            y={0}
            width={width}
            height={graphHeight}
            fill="transparent"
            onTouchStart={handleHover}
            onTouchMove={handleHover}
            onMouseMove={handleHover}
            onMouseLeave={resetDisplay}
          />
        </svg>
      )}
    </>
  )
}

const StyledMissingChart = styled.svg`
  text {
    font-size: 12px;
    font-weight: 400;
  }
`
const chartBottomPadding = 15
function MissingPriceChart({ width, height, message }: { width: number; height: number; message: ReactNode }) {
  const theme = useTheme()
  const midPoint = height / 2 + 45
  return (
    <StyledMissingChart data-cy="missing-chart" width={width} height={height} style={{ minWidth: '100%' }}>
      <path
        d={`M 0 ${midPoint} Q 104 ${midPoint - 70}, 208 ${midPoint} T 416 ${midPoint}
          M 416 ${midPoint} Q 520 ${midPoint - 70}, 624 ${midPoint} T 832 ${midPoint}`}
        stroke={theme.backgroundOutline}
        fill="transparent"
        strokeWidth="2"
      />
      {message && <TrendingUp stroke={theme.textTertiary} x={0} size={12} y={height - chartBottomPadding - 10} />}
      <text y={height - chartBottomPadding} x="20" fill={theme.chartblack54}>
        {message}
      </text>
    </StyledMissingChart>
  )
}
